import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import {
  FaTelegram,
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaLinkedin,
} from "react-icons/fa";
import {
  ChevronLeftIcon,
  HeartIcon,
  StarIcon,
  NotificationIcon,
} from "./Icons";

// Footer Component
const Footer = () => {
  const { t } = useTranslation(); // Initialize translation hook

  return (
    <footer className="bg-gray-800 text-white py-6 md:py-8">
      <div className="container mx-auto flex flex-col items-center px-4">
        <div className="flex flex-col items-center mb-6">
          <a
            href="https://www.gebetas.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/Gebeta.png" alt="Gebeta Logo" className="w-24 mb-2" />
          </a>
          <p className="text-xl md:text-2xl font-dancing">
            "Lettuce Take a Byte!"
          </p>
        </div>
        <div className="flex flex-col items-center mb-6 md:mb-0">
          <h3 className="text-lg font-semibold mb-2 font-bree text-center">
            {t("CONTACT US")}
          </h3>
          <p className="font-salsa text-center">
            {t("EMAIL")}:{" "}
            <a href="mailto:mail@gebeta.com" className="text-green-400">
              mail@gebetas.com
            </a>
          </p>
          <p className="font-salsa text-center">
            {t("PHONE")}:{" "}
            <a href="tel:+1234567890" className="text-green-400">
              (+251) 900296860
            </a>
          </p>
        </div>
        <div className="flex flex-col items-center mb-6 md:mb-0">
          <h3 className="text-lg font-semibold mb-2 font-bree text-center">
            {t("FOLLOW US")}
          </h3>
          <div className="flex space-x-4 justify-center">
            <a
              href="https://t.me/Gebeta_official"
              target="_blank"
              aria-label="Telegram"
              rel="noopener noreferrer"
            >
              <FaTelegram className="text-2xl hover:text-green-400" />
            </a>
            <a
              href="https://facebook.com/Gebeta Menu"
              target="_blank"
              aria-label="Facebook "
              rel="noopener noreferrer"
            >
              <FaFacebook className="text-2xl hover:text-green-400" />
            </a>
            <a
              href="https://twitter.com/Gebeta_official"
              target="_blank"
              aria-label="Twitter"
              rel="noopener noreferrer"
            >
              <FaTwitter className="text-2xl hover:text-green-400" />
            </a>
            <a
              href="https://instagram.com/Gebeta_official"
              target="_blank"
              aria-label="Instagram"
              rel="noopener noreferrer"
            >
              <FaInstagram className="text-2xl hover:text-green-400" />
            </a>
            <a
              href="https://youtube.com/@Gebeta_official"
              target="_blank"
              aria-label="Youtube"
              rel="noopener noreferrer"
            >
              <FaYoutube className="text-2xl hover:text-green-400" />
            </a>
            <a
              href="https://linkedin.com/in/BenjaminMenedo"
              target="_blank"
              aria-label="Linkedin "
              rel="noopener noreferrer"
            >
              <FaLinkedin className="text-2xl hover:text-green-400" />
            </a>
          </div>
        </div>
          <div className="flex flex-col items-center mb-3 md:mb-0">
        <p className="font-salsa text-center">
          <a
            href="/PrivacyPolicy" // Ensure this path matches your routing
            className="text-green-400 hover:underline"
          >
            {t("PRIVACY POLICY")}
          </a>
        </p>
      </div>{" "}
      <div className="flex flex-col items-center mb-6 md:mb-0">
        <p className="font-salsa text-center">
          <a
            href="/Terms" // Ensure this path matches your routing
            className="text-green-400 hover:underline"
          >
            {t("TERMS")}
          </a>
        </p>
      </div>
      </div>
      <div className="text-center mt-6 md:mt-8 font -salsa">
        <p className="text-sm bg-clip-text text-transparent bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">
          &copy; {new Date().getFullYear()} {t("COPYRIGHT")}
        </p>
      </div>
    </footer>
  );
};


const Item36 = () => {
  const location = useLocation();
  const { name, image, price, currency, 
  customText, beverages } = location.state || {};
  const [heartFilled, setHeartFilled] = useState(false);
  const [rating, setRating] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const savedHeartFilled = localStorage.getItem("heartFilled36");
    const savedRating = localStorage.getItem("rating36");

    if (savedHeartFilled) {
      setHeartFilled(JSON.parse(savedHeartFilled));
    }
    if (savedRating) {
      setRating(Number(savedRating));
    }
  }, []);

  const toggleHeart = () => {
    const newHeartFilled = !heartFilled;
    setHeartFilled(newHeartFilled);
    localStorage.setItem("heartFilled36", JSON.stringify(newHeartFilled));
  };

  const handleRatingClick = (index) => {
    const newRating = rating === index + 1 ? 0 : index + 1;
    setRating(newRating);
    localStorage.setItem("rating36", newRating);
  };

   const getRandomBeverages = (beverages) => {
    if (!Array.isArray(beverages) || beverages.length === 0) {
      return []; // Return an empty array if beverages is undefined or not an array
    }

    // Create a copy of the beverages array to avoid modifying the original
    const copy = [...beverages];

    // Shuffle the array using Fisher-Yates (Knuth) shuffle algorithm
    for (let i = copy.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [copy[i], copy[j]] = [copy[j], copy[i]]; // Swap elements
    }

    // Return the first 4 items from the shuffled array
    return copy.slice(0, 4);
  };

  // Ensure that beverages is properly checked before passing to the function
  const randomBeverages = getRandomBeverages(beverages);


  return (
    <div
      style={{ userSelect: "none" }}
      className="bg-gradient-to-r from-indigo-500 via-purple-600 to-pink-500 min-h-screen flex flex-col"
    >
      <div className="relative">
        <ChevronLeftIcon />
        <img
          src={`/assets/${image}`}
          alt="Hero"
          className="w-full h-[50vh] object-cover rounded-bl-3xl rounded-br-3xl"
        />
        <NotificationIcon />
        <h1 className="absolute top-4 left-1/2 transform -translate-x-1/2 text-lg sm:text-xl md:text-2xl lg:text-3xl font-bree font-extrabold text-white transition-colors duration-300 hover:text-yellow-300">
          {t('MENU')}
        </h1>
        <div className="absolute bottom-4 left-4">
          <p className="text-xl sm:text-xl md:text-3xl lg:text-4xl font-bold font-pacifico text-white">
            {t(customText)}
          </p>
          <div className="flex space-x-1 mt-1">
            {[...Array(5)].map((_, i) => (
              <StarIcon
                key={i}
                filled={i < rating}
                onClick={() => handleRatingClick(i)}
              />
            ))}
          </div>
        </div>
        <div
          onClick={toggleHeart}
          className="absolute bottom-4 right-4 rounded-full border border-white p-2 sm:p-3 md:p-4 flex justify-center items-center cursor-pointer"
        >
          <HeartIcon filled={heartFilled} />
        </div>
      </div>
    <div className="flex justify-between items-center px-6 sm:px-6 lg:px-8 py-2 mt-4">
       <div className="mr-40">
          <p className="text-2xl sm:text-2xl md:text-3xl lg:text-4xl font-bree font-normal text-white mb-1 leading-tight">
            {t(name)}
          </p>
        </div>
        <div className="bg-white bg-opacity-20 rounded-lg shadow-lg p-4 flex flex-col items-center backdrop-blur-sm">
          <p className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-raleway font-bold text-white">
            {t(price)}
          </p>
          <p className="text-md sm:text-xl md:text-2xl lg:text-3xl font-raleway font-bold text-white">
            {t(currency)}
          </p>
        </div>
      </div>
      <div className="flex flex-col items-center px-4 sm:px-6 lg:px-8 mt-4">
        <div className="bg-white bg-opacity-20 rounded-lg shadow-lg p-4 my-6 backdrop-blur-none max-w-full w-full mx-auto">
          <h2 className="text-2xl sm:text-2xl md:text-3xl lg:text-4xl text-center font-satisfy font-bold text-white mb-4">
            {t("INGREDIENTS")}
          </h2>
          <p className="text-md sm:text-lg md:text-xl lg:text-2xl font-bree font-normal text-white">
            {t("DESCRIPTION")}
          </p>
        </div>
        <div className="bg-white bg-opacity-20 rounded-lg shadow-lg p-4 my-6 backdrop-blur-none max-w-full w-full mx-auto">
          <h2 className="text-2xl sm:text-2xl md:text-3xl lg:text-4xl text-center font-satisfy font-bold text-white mb-4">
            {t("TITLE")}
          </h2>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="text-md sm:text-lg md:text-xl lg:text-2xl">
              <tr>
                <th className="px-4 py-2 text-left text-white font-bold font-bree">
                  {t("NUTRIENTS")}
                </th>
                <th className="px-4 py-2 text-left text-white font-bold font-bree">
                  {t("AMOUNTS")}
                </th>
              </tr>
            </thead>
            <tbody className="text-sm sm:text-md md:text-lg lg:text-xl divide-y divide-gray-200">
              <tr>
                <td className="px-4 py-2 font-normal font-bree text-white">
                  {t("CALORIES")}
                </td>
                <td className="px-4 py-2 font-normal font-bree text-white">
                  540 kcal
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2 font-normal font-bree text-white">
                  {t("CARBOHYDRATES")}
                </td>
                <td className="px-4 py-2 font-normal font-bree text-white">
                  45 g
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2 font-normal font-bree text-white">
                  {t("PROTEIN")}
                </td>
                <td className="px-4 py-2 font-normal font-bree text-white">
                  25 g
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2 font-normal font-bree text-white">
                  {t("TOTALFAT")}
                </td>
                <td className="px-4 py-2 font-normal font-bree text-white">
                  25 g
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2 font-normal font-bree text-white">
                  {t("DIETARYFIBER")}
                </td>
                <td className="px-4 py-2 font-normal font-bree text-white">
                  3 g
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2 font-normal font-bree text-white">
                  {t("SODIUM")}
                </td>
                <td className="px-4 py-2 font-normal font-bree text-white">
                  800 mg
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="bg-white bg-opacity-20 rounded-lg shadow-lg p-4 my-6 backdrop-blur-none max-w-full w-full mx-auto">
          <h2 className="text-2xl sm:text-2xl md:text-3xl lg:text-4xl text-center font-satisfy font-bold text-white mb-4">
            {t("BEVERAGES")}
          </h2>
          <p className="text-xl sm:text-xl md:text-2xl lg:text-3xl text-center font-salsa font-bold text-white mb-4">
            {t("HAVE_A_DRINK")}
          </p>
          <div className="grid grid-cols-2 gap-4">
            {randomBeverages.map((beverage) => (
                 <div className="bg-white rounded-xl overflow-hidden shadow-2xl cursor-pointer transform transition duration-200 hover:scale-105">
                  <img
                    src={`/assets/${beverage.image}`}
                    alt={beverage.name}
                    className="w-full h-44 object-cover object-center"
                  />
                  <div className="absolute bottom-0 left-0 right-0 bg-opacity-10 p-0 pl-3 pb-1 text-left text-lg font-bree text-white">
                    {t(beverage.name)}
                  </div>
                </div>
             ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Item36;