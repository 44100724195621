import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
} from "react-router-dom";
import Menu from "./Menu";
import Privacy from './Privacy'; // Import the Privacy Policy component
import Terms from './Terms'; // Import the Privacy Policy component

// Import all item components from the meals folder
import Item1 from "./meals/Item1"; // Cheese Burger
import Item2 from "./meals/Item2"; // Beef Burger
import Item3 from "./meals/Item3"; // Double Cheese Burger
import Item4 from "./meals/Item4"; // Double Burger
import Item5 from "./meals/Item5"; // Hamburger
import Item6 from "./meals/Item6"; // Vegetarian Burger
import Item7 from "./meals/Item7"; // Margherita Pizza
import Item8 from "./meals/Item8"; // Pepperoni Pizza
import Item9 from "./meals/Item9"; // Cheese Pizza
import Item10 from "./meals/Item10"; // Vegetarian Pizza
import Item11 from "./meals/Item11"; // Hawaiian Pizza
import Item12 from "./meals/Item12"; // Spaghetti Bolognese
import Item13 from "./meals/Item13"; // Carbonara
import Item14 from "./meals/Item14"; // Alfredo
import Item15 from "./meals/Item15"; // Pesto
import Item16 from "./meals/Item16"; // Lasagna
import Item17 from "./meals/Item17"; // Shrimp
import Item18 from "./meals/Item18"; // Salmon
import Item19 from "./meals/Item19"; // Lobster
import Item20 from "./meals/Item20"; // Scallops
import Item21 from "./meals/Item21"; // Tuna
import Item22 from "./meals/Item22"; // Beef Taco
import Item23 from "./meals/Item23"; // Chicken Taco
import Item24 from "./meals/Item24"; // Fish Taco
import Item25 from "./meals/Item25"; // Veggie Taco
import Item26 from "./meals/Item26"; // Carne Asada Taco
import Item27 from "./meals/Item27"; // Cheese Omelet
import Item28 from "./meals/Item28"; // Veggie Omelet
import Item29 from "./meals/Item29"; // Mushroom & Spinach Omelet
import Item30 from "./meals/Item30"; // Smoked Salmon Omelet
import Item31 from "./meals/Item31"; // Espresso
import Item32 from "./meals/Item32"; // Cappuccino
import Item33 from "./meals/Item33"; // Latte
import Item34 from "./meals/Item34"; // Mocha
import Item35 from "./meals/Item35"; // Cold Brew
import Item36 from "./meals/Item36"; // Orange Juice
import Item37 from "./meals/Item37"; // Apple Juice
import Item38 from "./meals/Item38"; // Cranberry Juice
import Item39 from "./meals/Item39"; // Pineapple Juice
import Item40 from "./meals/Item40"; // Grapes Juice
import Item41 from "./meals/Item41"; // Cola
import Item42 from "./meals/Item42"; // Lemon-Lime Soda
import Item43 from "./meals/Item43"; // Root Beer
import Item44 from "./meals/Item44"; // Ginger Ale
import Item45 from "./meals/Item45"; // Cream Soda
import Item46 from "./meals/Item46"; // Chocolate Milkshake
import Item47 from "./meals/Item47"; // Vanilla Milkshake
import Item48 from "./meals/Item48"; // Strawberry Milkshake
import Item49 from "./meals/Item49"; // Cookies & Cream Milkshake
import Item50 from "./meals/Item50"; // Mint Chocolate Chip Milkshake
import Item51 from "./meals/Item51"; // Vanilla Cake
import Item52 from "./meals/Item52"; // Chocolate Cake
import Item53 from "./meals/Item53"; // Red Velvet Cake
import Item54 from "./meals/Item54"; // Carrot Cake
import Item55 from "./meals/Item55"; // Tibs
import Item56 from "./meals/Item56"; // Seega Wot
import Item57 from "./meals/Item57"; // Gomen Besege
import Item58 from "./meals/Item58"; // Kik Alitcha
import Item59 from "./meals/Item59"; // Doro Wot
import Item60 from "./meals/Item60"; // Fir-Fir

const NotFound = () => {
  return (
    <div className="bg-gradient-to-r from-indigo-500 via-purple-600 to-pink-500 items-center text-center h-screen">
      <h1 className="text-7xl sm:text-7xl md:text-8xl lg:text-9xl font-mono font-bold text-white py-56">
        404
      </h1>
      <p className="text-2xl sm:text-2xl md:text-3xl lg:text-5xl font-shadows font-bold text-white -my-56">
        Page Not Found
      </p>
    </div>
  );
};

const ItemComponent = () => {
  const { itemId } = useParams(); // Get the itemId from the URL
  const itemComponents = {
    1: Item1,
    2: Item2,
    3: Item3,
    4: Item4,
    5: Item5,
    6: Item6,
    7: Item7,
    8: Item8,
    9: Item9,
    10: Item10,
    11: Item11,
    12: Item12,
    13: Item13,
    14: Item14,
    15: Item15,
    16: Item16,
    17: Item17,
    18: Item18,
    19: Item19,
    20: Item20,
    21: Item21,
    22: Item22,
    23: Item23,
    24: Item24,
    25: Item25,
    26: Item26,
    27: Item27,
    28: Item28,
    29: Item29,
    30: Item30,
    31: Item31,
    32: Item32,
    33: Item33,
    34: Item34,
    35: Item35,
    36: Item36,
    37: Item37,
    38: Item38,
    39: Item39,
    40: Item40,
    41: Item41,
    42: Item42,
    43: Item43,
    44: Item44,
    45: Item45,
    46: Item46,
    47: Item47,
    48: Item48,
    49: Item49,
    50: Item50,
    51: Item51,
    52: Item52,
    53: Item53,
    54: Item54,
    55: Item55,
    56: Item56,
    57: Item57,
    58: Item58,
    59: Item59,
    60: Item60,
  };

  const Component = itemComponents[itemId];
  return Component ? <Component /> : <NotFound />;
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Menu />} />
        <Route path="/item/:itemId" element={<ItemComponent />} />
        <Route path="/PrivacyPolicy" element={<Privacy />} />{" "}
        <Route path="/Terms" element={<Terms />} />{" "}
        {/* Privacy Policy Route */}
        <Route path="*" element={<NotFound />} /> {/* Catch-all for 404 */}
      </Routes>
    </Router>
  );
};

export default App;
