import React, { useState, useEffect } from "react";
import { FaSearch, FaDice } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next"; // Import the hook
import i18n from "./i18n"; // Import the translation config file
import {
  FaTelegram,
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaLinkedin,
} from "react-icons/fa";

const Header = ({ changeLanguage, selectedLanguage }) => {
  const [currentLang, setCurrentLang] = useState(selectedLanguage || "en");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleChange = (selectedLang) => {
    setCurrentLang(selectedLang);
    changeLanguage(selectedLang);
    setIsDropdownOpen(false); // Close dropdown after selection
  };

  const languageOptions = {
    en: { label: "EN", flag: "/assets/flags/En.png" },
    am: { label: "አማ", flag: "/assets/flags/Et.png" },
    es: { label: "ES", flag: "/assets/flags/Sp.png" },
    fr: { label: "FR", flag: "/assets/flags/Fr.png" },
    it: { label: "IT", flag: "/assets/flags/It.png" },
    zh: { label: "中文", flag: "/assets/flags/Ch.png" },
  };

  return (
    <header className="py-2 shadow-lg bg-gradient-to-r from-indigo-700 via-purple-400 to-pink-600">
      <div className="max-w-7xl mx-auto px-4 shadow-2x1 sm:px-6 lg:px-8 flex justify-center">
        <img
          src="/assets/logo.png"
          alt="Logo"
          className="w-20 h-auto mx-4 my-1 shadow-2x1 object-contain"
        />
      </div>
      {/* Language Selector positioned on the header image */}
      <div className="absolute top-4 right-4 mr-0 my-4 flex items-center justify-end w-full max-w-xs">
        <div className="relative">
          <button
            type="button"
            className="flex items-center backdrop-blur-xl bg-gradient-to-r from-pink-700 to-pink-400 text-black font-bree px-4 py-2 rounded-lg cursor-pointer transition-all duration-300 ease-in-out"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)} // Toggle dropdown visibility
          >
            <img
              src={languageOptions[currentLang].flag}
              alt={languageOptions[currentLang].label}
              className="w-5 h-5 mr-2" // Adjust size of the flag
            />
            <span>{languageOptions[currentLang].label}</span>
          </button>
          {isDropdownOpen && (
            <div className="absolute left-0 top-12 bg-white bg-opacity-80 backdrop-blur-lg rounded-lg shadow-lg z-10">
              {Object.entries(languageOptions).map(
                ([code, { label, flag }]) => (
                  <button
                    key={code}
                    onClick={() => handleChange(code)}
                    className="flex items-center w-full text-left px-4 py-2 text-black hover:bg-gray-200 font-bree"
                  >
                    <img
                      src={flag}
                      alt={label}
                      className="w-5 h-5 mr-2" // Adjust size of the flag
                    />
                    {label}
                  </button>
                )
              )}
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

const SearchBar = ({ searchQuery, setSearchQuery }) => {
  const { t } = useTranslation(); // Initialize translation hook
  return (
    <div className="relative flex justify-center w-full">
      <input
        id="search"
        type="text"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder={t("SEARCH")}
        className="w-full md:w-3/4 lg:w-1/2 font-bree bg-gradient-to-r from-cyan-600 to-purple-600 text-white placeholder-white py-3 pl-10 pr-4 rounded-none shadow-2xl outline-none transition-all duration-300 hover:rounded-2xl focus:rounded-2xl hover:shadow-2xl hover:scale-95 focus:ring-0 focus:outline-none"
        style={{
          width: "calc(100%)",
          height: "3rem",
        }}
        tabIndex="0"
      />
      <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-white text-lg" />
    </div>
  );
};

const Footer = () => {
  const { t } = useTranslation(); // Initialize translation hook

  return (
    <footer className="bg-gray-800 text-white py-6 md:py-8">
      <div className="container mx-auto flex flex-col items-center px-4">
        <div className="flex flex-col items-center mb-6">
          <a
            href="https://www.gebetas.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/Gebeta.png" alt="Gebeta Logo" className="w-24 mb-2" />
          </a>
          <p className="text-xl md:text-2xl font-dancing">
            "Lettuce Take a Byte!"
          </p>
        </div>
        <div className="flex flex-col items-center mb-6 md:mb-0">
          <h3 className="text-lg font-semibold mb-2 font-bree text-center">
            {t("CONTACT US")}
          </h3>
          <p className="font-salsa text-center">
            {t("EMAIL")}:{" "}
            <a href="mailto:mail@gebeta.com" className="text-green-400">
              mail@gebetas.com
            </a>
          </p>
          <p className="font-salsa text-center">
            {t("PHONE")}:{" "}
            <a href="tel:+1234567890" className="text-green-400">
              (+251) 900296860
            </a>
          </p>
        </div>
        <div className="flex flex-col items-center mb-6 md:mb-0">
          <h3 className="text-lg font-semibold mb-2 font-bree text-center">
            {t("FOLLOW US")}
          </h3>
          <div className="flex space-x-4 justify-center">
            <a
              href="https://t.me/Gebeta_official"
              target="_blank"
              aria-label="Telegram"
              rel="noopener noreferrer"
            >
              <FaTelegram className="text-2xl hover:text-green-400" />
            </a>
            <a
              href="https://facebook.com/Gebeta Menu"
              target="_blank"
              aria-label="Facebook "
              rel="noopener noreferrer"
            >
              <FaFacebook className="text-2xl hover:text-green-400" />
            </a>
            <a
              href="https://twitter.com/Gebeta_official"
              target="_blank"
              aria-label="Twitter"
              rel="noopener noreferrer"
            >
              <FaTwitter className="text-2xl hover:text-green-400" />
            </a>
            <a
              href="https://instagram.com/Gebeta_official"
              target="_blank"
              aria-label="Instagram"
              rel="noopener noreferrer"
            >
              <FaInstagram className="text-2xl hover:text-green-400" />
            </a>
            <a
              href="https://youtube.com/@Gebeta_official"
              target="_blank"
              aria-label="Youtube"
              rel="noopener noreferrer"
            >
              <FaYoutube className="text-2xl hover:text-green-400" />
            </a>
            <a
              href="https://linkedin.com/in/BenjaminMenedo"
              target="_blank"
              aria-label="Linkedin "
              rel="noopener noreferrer"
            >
              <FaLinkedin className="text-2xl hover:text-green-400" />
            </a>
          </div>
        </div>
      </div>{" "}
      <div className="flex flex-col items-center mb-3 md:mb-0">
        <p className="font-salsa text-center">
          <a
            href="/PrivacyPolicy" // Ensure this path matches your routing
            className="text-green-400 hover:underline"
          >
            {t("PRIVACY POLICY")}
          </a>
        </p>
      </div>{" "}
      <div className="flex flex-col items-center mb-6 md:mb-0">
        <p className="font-salsa text-center">
          <a
            href="/Terms" // Ensure this path matches your routing
            className="text-green-400 hover:underline"
          >
            {t("TERMS")}
          </a>
        </p>
      </div>
      <div className="text-center mt-6 md:mt-8 font-salsa">
        <p className="text-sm bg-clip-text text-transparent bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">
          &copy; {new Date().getFullYear()} {t("COPYRIGHT")}
        </p>
      </div>
    </footer>
  );
};

// Skeleton Loader Component
const SkeletonLoader = () => {
  return (
    <div className="bg-gray-300 animate-pulse rounded-lg p-4 flex items-center mb-4">
      <div className="w-20 h-20 bg-gray-400 rounded-lg mr-4"></div>
      <div className="flex-1">
        <div className="h-5 bg-gray-400 rounded mb-2"></div>
        <div className="h-4 bg-gray-400 rounded w-3/4"></div>
      </div>
    </div>
  );
};

const Menu = () => {
  const [menuItems, setMenuItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true); // Loading state
  const { t } = useTranslation(); // Initialize translation hook
  const navigate = useNavigate(); // Only declare navigate once

  // Sample beverage data
  const beverages = [
    {
      
      name: "COLA",
      image: "Cola.jfif",
    },
    {
    
      name: "COLD BREW",
      image: "Lemon-Lime Soda.jfif",
    },
    {
      name: "ESPRESSO",
      image: "Espresso.jfif",
    },
    {
      name: "GINGER ALE",
      image: "Ginger Ale.jfif",
    },
    {
      name: "CAPPUCCINO",
      image: "Cappuccino.jfif",
    },
    {
      name: "LATTE",
      image: "Latte.jfif",
    },
    {
      name: "MOCHA",
      image: "Mocha.jfif",
    },
    {
      name: "CREAM SODA",
      image: "Cream Soda.jfif",
    },
    {
      
      name: "ORANGE JUICE",
      image: "Orange Juice.jfif",
    },
    {
    
      name: "APPLE JUICE",
      image: "Apple Juice.jfif",
    },
    {
      name: "CRANBERRY JUICE",
      image: "Cranberry Juice.jfif",
    },
    {
      name: "PINEAPPLE JUICE",
      image: "Pineapple Juice.jfif",
    },
    {
      name: "GRAPES JUICE",
      image: "Grapes Juice.jfif",
    },
    {
      name: "CHOCOLATE",
      image: "Chocolate Milkshake.jfif",
    },
    {
      name: "VANILLA",
      image: "Vanilla Milkshake.jfif",
    },
    {
      name: "STRAWBERRY",
      image: "Strawberry Milkshake.jfif",
    },
    {
      name: "COOKIES & CREAM",
      image: "Cookies and Cream Milkshake.jfif",
    },
    {
      name: "MINT CHOCOLATE CHIP",
      image: "Mint Chocolate Chip Milkshake.jfif",
    },
  ];

  useEffect(() => {
    setLoading(true); // Set loading to true before fetching
    fetch("https://gebetas.com/api.php")
      .then((response) => response.json())
      .then((data) => {
        setMenuItems(data);
        setLoading(false); // Set loading to false after fetching
      })
      .catch((error) => {
        console.error("Error fetching menu items:", error);
        setLoading(false); // Set loading to false on error
      });
  }, []);

  const filteredItems = menuItems.filter((item) =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const getRandomItem = () => {
    const randomIndex = Math.floor(Math.random() * filteredItems.length);
    const selectedItem = filteredItems[randomIndex];
    if (selectedItem) {
      navigate(`/item/${selectedItem.id}`, {
        state: {
          name: selectedItem.name,
          image: selectedItem.image,
          price: selectedItem.price,
          currency: selectedItem.currency,
          customText: selectedItem.customText,
          beverages, // Pass the beverages array
        },
      });
    }
  };
  // Save the selected language in local storage and set it in i18n
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng); // Store the selected language
  };

  // Check if there is a language stored in local storage
  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, []);

  return (
    <div>
      {loading ? (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-8 py-4">
            {Array.from({ length: 60 }).map((_, index) => (
              <SkeletonLoader key={index} />
            ))}
          </div>
        </div>
      ) : (
        <div style={{ userSelect: "none" }}>
          <Header
            changeLanguage={changeLanguage}
            selectedLanguage={localStorage.getItem("language") || "en"}
          />
          <SearchBar
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
          <div className="bg-gradient-to-r from-indigo-500 via-purple-600 to-pink-500 py-12 text-white font-serif font-bold">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <h2 className="text-3xl text-center">{t("MENU")}</h2>
              <div className="grid grid-cols-1 gap-8">
                {filteredItems.length ? (
                  filteredItems.map((item) => (
                    <React.Fragment key={item.id}>
                      {item.customText && (
                        <div className="inline-block p-2 border-2 border-white rounded-full mt-4 mb-4">
                          <h3 className="text-2xl text-center text-white font-bold tracking-widest">
                            {t(item.customText)}
                          </h3>
                        </div>
                      )}
                      <Link
                        to={`/item/${item.id}`}
                        state={{
                          name: item.name,
                          image: item.image,
                          price: item.price,
                          currency: item.currency,
                          customText: item.customText,
                          beverages,
                        }}
                        preload="true"
                      >
                        <div
                          className={`flex items-center border border-transparent rounded-xl p-4 shadow-lg bg-black bg-opacity-40 hover:bg-opacity-60 transition-all duration-300 ease-in-out hover:border-fuchsia-400 hover:shadow-fuchsia-400 group ${
                            item.stock ? "" : "line-through text-red-500"
                          }`}
                        >
                          <img
                            src={`/assets/${item.image}`}
                            alt={item.name}
                            className="w-20 h-20 rounded-lg object-cover mr-4 shadow-lg group-hover:shadow-fuchsia-400 transition-shadow duration-300 ease-in-out"
                          />
                          <div className="flex-1 pl-2">
                            <h3 className="text-lg font-bold text-white group-hover:text-fuchsia-400 tracking-widest break-words">
                              {t(item.name)}
                            </h3>
                          </div>
                          <div className="flex flex-col justify-between items-center p-2 rounded-lg bg-black bg-opacity-40 group-hover:shadow-lg transition-shadow duration-300 ease-in-out">
                            <span className="text-lg font-bold text-white group-hover:text-fuchsia-400">
                              {item.price}
                            </span>
                            <span className="text-sm text-gray-100 group-hover:text-fuchsia-400">
                              {t(item.currency)}
                            </span>
                          </div>
                        </div>
                      </Link>
                    </React.Fragment>
                  ))
                ) : (
                  <p className="text-center text-white font-bree">
                    No items available
                  </p>
                )}
              </div>
            </div>
            <div className="flex justify-center py-8">
              <button
                onClick={getRandomItem}
                className="relative text-lg sm:text-lg md:text-xl lg:text-2xl px-12 py-3 bg-gradient-to-r from-cyan-600 to-indigo-600 text-white hover:text-fuchsia-400 font-bold rounded-lg hover:shadow-2xl transition-all duration-300 ease-in-out font-dancing"
              >
                <FaDice className="absolute left-4 top-1/2 transform -translate-y-1/2 size-9 text-white text-lg cursor-pointer hover:text-fuchsia-400 transition-all duration-300" />
                <span className="pl-6">{t("SELECT RANDOMLY")}</span>
              </button>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default Menu;
