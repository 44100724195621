import React from "react";
import { Link } from "react-router-dom";

// Chevron Left Icon
export const ChevronLeftIcon = () => (
  <Link to={"/"}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="absolute top-4 left-4 cursor-pointer text-white transition-colors duration-300 hover:text-yellow-300
                 h-6 w-6 sm:h-8 sm:w-8 md:h-9 md:w-9 lg:h-12 lg:w-12"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M15 19l-7-7 7-7"
      />
    </svg>
  </Link>
);

// Heart Icon
export const HeartIcon = ({ filled }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={`h-6 w-6 ${filled ? "text-pink-500" : "text-white"}`}
    fill={filled ? "currentColor" : "none"}
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
    />
  </svg>
);

// Star Icon
export const StarIcon = ({ filled, onClick }) => (
  <svg
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5 sm:h-5 sm:w-5 md:h-6 md:w-6 lg:h-7 lg:w-7 text-yellow-500 cursor-pointer"
    fill={filled ? "currentColor" : "none"}
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M11.049 2.927C11.3 2.368 12.7 2.368 12.951 2.927l1.562 3.163a1.5 1.5 0 001.133.818l3.485.506c.347.05.654.287.843.637.188.35.232.76.119 1.146l-2.527 2.464a1.5 1.5 0 00-.433 1.334l.597 3.484c.059.34-.014.686-.18.973-.166.287-.414.489-.708.605a1.5 1.5 0 01-1.031.005l-3.127-1.646a1.5 1.5 0 00-1.402 0l-3.127 1.646a1.5 1.5 0 01-1.031-.005c-.294-.116-.542-.318-.708-.605a1.5 1.5 0 01-.18-.973l.597-3.484a1.5 1.5 0 00-.433-1.334L2.454 9.79a1.5 1.5 0 01.119-1.146c.188-.35.496-.587.843-.637l3.485-.506a1.5 1.5 0 001.133-.818l1.562-3.163z"
    />
  </svg>
);

// Notification Icon
export const NotificationIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="absolute top-4 right-4 cursor-pointer text-white transition-colors duration-300 hover:text-yellow-300
               h-6 w-6 sm:h-8 sm:w-8 md:h-10 md:w-10 lg:h-12 lg:w-12"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M15 17h5l-1.405-1.405A2.25 2.25 0 0018 14.25V14a6.75 6.75 0 00-13.5 0v.25a2.25 2.25 0 00-.595 1.345L4 17h5m3 0h-6m6 0v1.5a2.25 2.25 0 01-2.25 2.25h-.5A2.25 2.25 0 018.5 18.5V17"
    />
  </svg>
);
