import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend"; // For loading translations from backend or files
import LanguageDetector from "i18next-browser-languagedetector"; // For detecting user's language

i18n
  .use(Backend) // Use backend to load translations
  .use(LanguageDetector) // Detect language based on browser settings or user choice
  .use(initReactI18next) // Passes the i18n instance to react-i18next
  .init({
    fallbackLng: "en", // Default language if no match is found
    debug: true, // Enable debug mode; set to false in production
    lng: "en", // Initial language
    supportedLngs: ["en", "fr", "am", "zh", "es", "it"], // Supported languages
    backend: {
      loadPath: "/locales/{{lng}}/translation.json", // Path to translation files
    },
    detection: {
      order: ["querystring", "cookie", "localStorage", "navigator", "htmlTag"], // Language detection order
      caches: ["cookie", "localStorage"], // Cache user language in cookies/local storage
    },
    interpolation: {
      escapeValue: false, // No need for XSS protection as React is secure
    },
    react: {
      useSuspense: false, // Set to true if using suspense for async loading
    },
  });

export default i18n;
